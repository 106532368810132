import { useEffect } from "react";
import CarouselComponent from "../components/CarrouselComponent";
import { addSeries } from "../firebase-actions";

export default function HomePage() {

  // useEffect(() => {
  //   const add = async () => {
  //     await addSeries({
  //       id: "zee",
  //       title: "Zee",
  //       description: "Allemaal zee",
  //       images: []
  //     })
  //   }
  //   // add();
  // },[])

  return (
    <div className="flex flex-col gap-6">
      <div className="font-gray-500 font-semibold">
        <p className="mb-2">Welkom op mijn website!</p>
        <p className="mb-2">Mijn naam is Ans Christiaens. Kijk rustig rond op deze website om mijn werk te bekijken.</p>
      </div>
      <div className="p-4 font-gray-500 font-semibold bg-slate-100 rounded flex flex-col sm:flex-row gap-6">
      <div className="max-w-[150px] flex flex-col justify-center w-[150px]"><img src="logo-cvi-dark-grey.svg" alt="cvi" className="" /></div>
        <div className="flex-1 mt-4">
          <p className="mb-2">Het innovatiehuis in 's-Hertogenbosch biedt een podium aan Bossche kunstenaars. 
            In het kader van wisselkunst is er steeds ander werk te bekijken voor enkele maanden. 
            Vanaf 13 januari wordt mijn werk geëxposeerd. 
            Kijk op <a href="https://www.cviweb.nl/area-item/anschristiaens/" target="_blank" className="text-blue-600">Het CVI Innovatiehuis</a> voor meer informatie.
          </p>
        </div>
      </div>
      <div className="flex">
        <CarouselComponent />
      </div>      
    </div>
  )
}